.root {
  border: none;
  background: var(--mantine-color-zorroWhite-0);
  overflow: hidden;

  --mantine-datatable-shadow-background-right: 0;
  --mantine-datatable-shadow-background-left: 0;
  --mantine-datatable-shadow-background-top: 0;
  --checkbox-radius: 4px;
}

.row {
  td {
    border-color: var(--mantine-color-zorroPlum-1) !important;
  }

  &:hover {
    background-color: var(--mantine-color-zorroPlum-1) !important;
  }

  &[data-selected='true'] > td {
    border-color: var(--mantine-color-zorroGray-8) !important;
    background: var(--mantine-color-zorroGray-8);
    pointer-events: visible;

    ::before {
      content: none;
    }
  }

  &[data-pin-first-column='true'] {
    &[data-is-selectable='false'] {
      td:first-of-type {
        position: sticky;
        z-index: 1;
        left: 0;
      }
    }

    &[data-is-selectable='true'] {
      td:nth-of-type(2) {
        position: sticky;
        z-index: 1;
        left: 3.3rem;
      }
    }
  }
}

.table {
  td {
    padding: var(--mantine-spacing-xs) var(--mantine-spacing-md);
  }

  tr td::before {
    background: none !important;
  }

  &[data-has-groups='true'] {
    th {
      border-color: var(--mantine-color-zorroWhite-0) !important;
      border-width: 3px;
    }
  }

  &[data-pin-first-column='true'] {
    &[data-is-selectable='false'] {
      tr:last-child th:first-child {
        position: sticky;
        z-index: 1;
        left: 0;
      }
    }

    &[data-is-selectable='true'] {
      tr th:nth-of-type(2) {
        position: sticky;
        z-index: 1;
        left: 0;
      }

      tr th:nth-of-type(2) {
        left: 3.3rem;
      }
    }

    &:has([data-shadow-visible='true']) {
      tbody td:nth-of-type(2),
      tr th:nth-of-type(2) {
        border-right: 1px solid var(--mantine-color-zorroGray-2) !important;
      }
    }
  }
}

.header {
  color: var(--mantine-color-zorroCoal-9);

  tr:first-of-type {
    background-color: var(--mantine-color-zorroPlum-1);
  }

  th {
    border-color: var(--mantine-color-zorroPlum-1);
    color: var(--mantine-color-zorroGray-4);
    font-weight: 500;
    border-bottom: 0;
    z-index: 1;

    :global(.mantine-datatable-header-cell-filter-action-icon) {
      background-color: transparent;
    }
  }
}
