.list {
  border-color: var(--mantine-color-zorroIris-4);
}

.tab {
  border-color: var(--mantine-color-zorroWhite-0);
  border-bottom-color: var(--mantine-color-zorroGray-1);

  &[data-active='true'] {
    border-bottom-color: var(--mantine-color-zorroIris-9);
    border-bottom-width: 3px;
    font-weight: 600;
  }
}

.tabLabel {
  color: var(--mantine-color-zorroCoal-9);
}

.panel {
  padding-top: var(--mantine-spacing-md);
}
