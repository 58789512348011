.root {
  box-shadow: 0 0.375rem 0.75rem 0 rgba(181, 181, 181, 0.8);
  border-radius: 100%;
  padding: 0.375rem;
  height: 3rem;
  width: 3rem;

  &:hover:not([disabled]),
  &:focus:not([disabled]) {
    background-color: var(--mantine-color-zorroIris-9);
  }
}

.section {
  margin-left: 0;
}
