.root {
  align-items: center;
  display: flex;

  .body {
    align-items: center;
  }

  .label {
    color: var(--mantine-color-zorroCoal-9);
  }

  .input {
    border-width: 2px;
    border-color: var(--mantine-color-zorroGray-5);
    border-radius: var(--mantine-radius-sm);

    &:hover {
      border-color: var(--mantine-color-zorroGray-4);
    }
  }

  &[data-with-border='true'] {
    padding: var(--mantine-spacing-sm) var(--mantine-spacing-md);
    background-color: var(--mantine-color-zorroWhite-0);
    box-shadow: inset 0 0 0 1px var(--mantine-color-zorroGray-4);
    border-radius: var(--mantine-radius-sm);
  }

  &[data-checked='true'] {
    font-weight: 400;

    .label {
      font-weight: 400;
    }

    .input {
      background-color: var(--mantine-color-zorroCoal-9);
      border-color: var(--mantine-color-zorroCoal-9);

      &:hover {
        background-color: var(--mantine-color-zorroGray-4);
        border-color: var(--mantine-color-zorroGray-4);
      }
    }
  }

  &[data-disabled='true'] {
    pointer-events: none;
    opacity: 0.5;

    .label {
      color: var(--mantine-color-zorroCoal-9);
    }

    .icon {
      color: var(--mantine-color-zorroGray-2);
    }

    .input {
      background-color: var(--mantine-color-zorroGray-1);
      border-color: var(--mantine-color-zorroGray-2);
    }

    &[data-with-border='true'] {
      box-shadow: inset 0 0 0 1px var(--mantine-color-zorroGray-6);
    }
  }

  &[data-readonly='true'] {
    pointer-events: none;
    display: none;

    &[data-checked='true'] {
      display: block;
      .label {
        color: var(--mantine-color-zorroCoal-9);
      }

      .icon {
        color: var(--mantine-color-zorroCoal-9);
      }

      .input {
        background-color: transparent;
        border-color: transparent;
      }
    }
  }
}
