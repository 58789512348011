.root {
  background-color: var(--mantine-color-zorroGray-8);
  transition: background-color 0.25s ease-out;
  border-radius: var(--mantine-radius-md);
  padding: 0 var(--mantine-spacing-md);
  position: absolute;
  left: 18.75rem;
  height: 3rem;
  width: 25rem;

  &:hover {
    background-color: #e5e6ed;
  }
}
