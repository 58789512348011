.root {
  justify-content: space-between;

  &[data-size='lg'] {
    .label {
      font-size: var(--mantine-font-size-sm);
      font-weight: 400;
    }
    .input {
      min-height: 3rem;
      height: 3rem;
    }
  }

  &[data-size='md'] {
    .input {
      min-height: 2.5rem;
      height: 2.5rem;
    }
  }

  &[data-size='sm'] {
    .input {
      min-height: 2rem;
      height: 2rem;
    }
    .inputField {
      font-size: var(--mantine-font-size-sm);

      &::placeholder {
        font-size: var(--mantine-font-size-sm);
      }
    }
  }

  .wrapper {
    justify-content: space-between;
  }

  .inputField {
    font-size: var(--mantine-font-size-md);

    &::placeholder {
      color: var(--mantine-color-zorroGray-4);
      font-weight: 400;
      font-size: var(--mantine-font-size-md);
    }
  }

  .input {
    border-radius: var(--mantine-radius-sm);
    border-color: var(--mantine-color-zorroGray-2);
    padding-right: 5rem;

    &:hover {
      border-color: var(--mantine-color-zorroGray-6);
    }

    &[data-expanded='true'] {
      border-color: var(--mantine-color-zorroGray-4);
      & + .section[data-position='right'] {
        .chevron {
          pointer-events: all;
          transform: rotate(180deg);
        }
      }
    }

    & + .section[data-position='right'] {
      width: fit-content;
      padding: 0 var(--mantine-spacing-md);
      pointer-events: none;
      padding-left: 0;
      padding-right: 0.25rem;
      .target {
        pointer-events: all;
        color: var(--mantine-color-zorroCoal-9);
        background-color: #f7f9fb;
        padding: 0.25rem 0.5rem;
        height: fit-content;
        font-size: var(--mantine-font-size-sm);
        &:hover:not([disabled]) {
          background-color: #f7f9fb;
        }
      }

      .remove {
        pointer-events: all;
      }
    }

    &[data-disabled='true'] {
      background-color: transparent;
      border: none;
    }
  }

  .pill-group {
    justify-content: space-between;
    align-items: center;
    height: 100%;
    flex-wrap: nowrap;
    .pills-wrapper {
      max-width: calc(100% - 6.25rem);
    }
  }
}

.popoverDropdown {
  border: none;
  box-shadow: 0px 6px 20px 0px #0000001a;
  width: fit-content;
  max-width: 15rem;
  padding: var(--mantine-spacing-xs);
  border-radius: var(--mantine-radius-sm);
}

.dropdownPill {
  background-color: #e0e3fe;
  font-size: var(--mantine-font-size-sm);
  padding: 0.125rem 0.5rem;
  width: fit-content;
  max-width: 100%;
  border-radius: var(--mantine-radius-sm);
  display: flex;
  flex-wrap: nowrap;
  span {
    font-size: var(--mantine-font-size-sm);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
