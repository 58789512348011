.root {
  pointer-events: all;
  border-radius: var(--mantine-radius-sm);
  color: var(--mantine-color-zorroCoal-9);
  background-color: #f7f9fb;
  padding: 0.25rem 0.5rem;
  height: fit-content;
  font-size: var(--mantine-font-size-sm);
  &:hover:not([disabled]) {
    background-color: #f7f9fb;
  }
}
