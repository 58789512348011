.root {
  transition: background-color 0.2s ease-out;
  border: 1px solid var(--mantine-color-zorroGray-2);
  padding: 0.25rem var(--mantine-spacing-sm);
  border-radius: 32px;

  &:hover {
    border-color: var(--mantine-color-zorroPlum-0);
    background-color: #e0e3fe;
  }

  &[data-checked='true'] {
    background-color: #e0e3fe;

    &:hover {
      background-color: var(--mantine-color-zorroPlum-0);
    }
  }
}
