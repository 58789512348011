.root {
  .stepIcon {
    border-width: 2px;
  }

  .content {
    margin: auto;
    display: flex;
    padding-top: 0;
    max-width: 43rem;
    align-items: center;
    flex-direction: column;
  }

  &[data-variant='outline'] {
    .stepIcon {
      background-color: var(--mantine-color-zorroIris-1);
      border-color: var(--mantine-color-zorroIris-1);
      color: var(--mantine-color-zorroIris-9);

      &[data-progress='true'] {
        border-color: var(--mantine-color-zorroIris-9);
      }

      &[data-completed='true'] {
        background-color: var(--mantine-color-zorroIris-9);
        border-color: var(--mantine-color-zorroIris-9);
      }
    }

    .separator {
      &:not(&[data-active='true']) {
        background-color: var(--mantine-color-zorroGray-6);
        height: 2px;
      }
    }
  }

  &[data-variant='headless'] {
    .separator,
    .stepIcon {
      display: none;
    }
  }

  &[data-variant='modern'] {
    .steps {
      gap: 8px;
    }

    .separator {
      background-color: var(--mantine-color-zorroGray-5);
      border-radius: 32;
      min-width: 28px;
      margin: 0;
    }

    .stepLabel {
      color: var(--mantine-color-zorroGray-4);
      text-wrap: nowrap;
    }

    [data-progress='true'] {
      .stepLabel {
        color: var(--mantine-color-zorroCoal-9);
      }
    }

    .step {
      margin-left: 4px;

      .stepIcon {
        background-color: var(--mantine-color-zorroGray-10);
        border: 2px solid var(--mantine-color-zorroGray-5);
        transition: filter ease-out 0.2s;
        outline: 2px solid transparent;
        outline-offset: 3px;
        font-weight: 400;
        min-height: 32px;
        min-width: 32px;
        height: 28px;
        width: 28px;
        gap: 0;

        &[data-completed='true'] {
          background-color: var(--mantine-color-zorroGreen-6);
          border-color: var(--mantine-color-zorroGreen-6);
        }

        &[data-progress='true'] {
          background-color: var(--mantine-color-zorroCoal-9);
          outline-color: var(--mantine-color-zorroCoal-9);
          border-color: var(--mantine-color-zorroCoal-9);
          color: var(--mantine-color-zorroWhite-0);
          min-height: 28px;
          min-width: 28px;
        }
      }

      &[data-partial='true'] {
        .stepIcon {
          border-color: var(--mantine-color-zorroGray-6);
          background-color: transparent;
          outline-color: transparent;
          overflow: hidden;

          .stepCompletedIcon svg {
            scale: 1.8;
          }
        }

        &[data-partial-required='true'] .stepIcon {
          border-color: var(--mantine-color-zorroFire-7);
          svg path {
            stroke: var(--mantine-color-zorroFire-7);
            fill: var(--mantine-color-zorroFire-7);
          }
        }
      }

      &[data-navigable='true'] {
        .stepIcon {
          cursor: pointer;

          &:hover {
            filter: brightness(0.95);
          }
        }

        .stepLabel {
          cursor: pointer;

          &:hover {
            color: var(--mantine-color-zorroCoal-9);
          }
        }
      }
    }
  }
}
