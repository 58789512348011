.root {
  font-size: var(--mantine-font-size-md);
  border-radius: 0.25rem;
  border-width: 0;
  font-weight: 500;

  &[data-size='xs'] {
    font-size: var(--mantine-font-size-xs);
    padding: 0.375rem 0.5rem;
    border-radius: 0.25rem;
  }

  &[data-size='sm'] {
    font-size: var(--mantine-font-size-sm);
    padding: 0.375rem 0.5rem;
    border-radius: 0.25rem;
    line-height: 1.25rem;
  }

  &[data-size='md'] {
    font-size: var(--mantine-font-size-md);
    padding: 0.375rem 1rem;
    line-height: 1.5rem;
    border-radius: 0.25rem;
    height: 2.5rem;
  }

  &[data-size='lg'] {
    font-size: var(--mantine-font-size-md);
    line-height: 1.5rem;
    padding: 0.75rem 1.5rem;
  }

  &[data-loading] {
    pointer-events: initial;
  }

  &[data-disable-hover='true'] {
    pointer-events: none;
  }

  &[data-variant='primary'] {
    background-color: var(--mantine-color-zorroIris-9);

    &[disabled] {
      background-color: var(--mantine-color-zorroGray-8);
      color: var(--mantine-color-zorroGray-4);
    }

    &:hover:not([disabled]),
    &:active:not([disabled]) {
      background-color: var(--mantine-color-zorroPlum-9);
    }
  }

  &[data-variant='primary-red'] {
    background-color: var(--mantine-color-zorroFire-8);

    &:hover:not([disabled]),
    &:active:not([disabled]) {
      background-color: var(--mantine-color-zorroFire-10);
    }
  }

  &[data-variant='secondary'] {
    background-color: var(--mantine-color-zorroWhite-0);
    border: solid 1px var(--mantine-color-zorroGray-5);
    color: var(--mantine-color-zorroCoal-9);

    &[disabled] {
      background-color: var(--mantine-color-zorroGray-7);
      color: var(--mantine-color-zorroGray-4);
    }

    &:hover:not([disabled]) {
      border: solid 1px var(--mantine-color-zorroGray-5);
      background-color: var(--mantine-color-zorroGray-7);
    }
    &:active:not([disabled]) {
      border: solid 1px var(--mantine-color-zorroIris-9);
      background-color: var(--mantine-color-zorroPrimaryButtonSelected-0);
    }
  }

  &[data-variant='tertiary'] {
    color: var(--mantine-color-zorroCoal-9);
    background-color: transparent;

    &[disabled] {
      color: var(--mantine-color-zorroGray-4);
    }

    &:hover:not([disabled]) {
      background-color: var(--mantine-color-zorroGray-7);
    }

    &:active:not([disabled]) {
      background-color: var(--mantine-color-zorroPrimaryButtonSelected-0);
    }

    &:hover:active:not([disabled]) {
      background-color: var(--mantine-color-zorroPrimaryButtonSelected-0);
    }
  }

  &[data-variant='filled'] {
    &[disabled] {
      background-color: var(--mantine-color-zorroGray-6);
      color: var(--mantine-color-zorroWhite-0);
    }
  }

  &[data-variant='outline'] {
    border: 2px solid var(--mantine-color-zorroIris-9);

    &[disabled] {
      background-color: var(--mantine-color-zorroGray-0);
      border-color: var(--mantine-color-zorroGray-6);
      color: var(--mantine-color-zorroGray-6);
    }

    &:hover:not([disabled]) {
      background-color: var(--mantine-color-zorroIris-9);
      color: var(--mantine-color-zorroGray-0);
    }
  }

  &[data-variant='subtle'] {
    background-color: transparent;
    height: auto;

    &[disabled] {
      color: var(--mantine-color-zorroGray-6);
    }

    &:hover:not([disabled]) {
      color: var(--mantine-color-zorroPlum-9);
    }
  }
}

.inner {
  height: min-content;
}

.label {
  overflow: visible;
}
