.root {
  align-items: center;
  display: flex;

  .body {
    align-items: center;
  }

  .label {
    color: var(--mantine-color-zorroCoal-9);
    margin-bottom: 0.25rem;
  }

  .radio {
    border-width: 2px;
    border-color: var(--mantine-color-zorroGray-5);

    &:hover {
      border-color: var(--mantine-color-zorroGray-4);
    }
  }

  &[data-checked='true'] {
    font-weight: 500;

    .label {
      font-weight: 500;
    }

    svg circle {
      fill: var(--mantine-color-zorroCoal-9);
    }

    .radio {
      background-color: var(--mantine-color-zorroWhite-0);
      border-width: 2px;
      border-color: var(--mantine-color-zorroCoal-9);
    }
  }

  &[data-disabled='true'] {
    pointer-events: none;

    .label {
      color: var(--mantine-color-zorroGray-6);
    }

    .radio {
      background-color: var(--mantine-color-zorroWhite-0);
      border-color: var(--mantine-color-zorroGray-6);
    }
  }

  &[data-with-border='true'] {
    padding: var(--mantine-spacing-sm) var(--mantine-spacing-md);
    background-color: var(--mantine-color-zorroWhite-0);
    border: 1px solid var(--mantine-color-zorroGray-6);
    border-radius: var(--mantine-radius-md);
  }
}
