$custom-item-width: 54px;

.root {
  &[data-size='lg'] {
    .input {
      min-height: 3rem;
      height: 3rem;
    }
  }

  &[data-size='md'] {
    .input {
      min-height: 2.5rem;
      height: 2.5rem;
    }
  }

  &[data-size='sm'] {
    .input {
      min-height: 2rem;
      height: 2rem;
      font-size: var(--mantine-font-size-sm);
    }
  }
}

.input {
  border-color: var(--mantine-color-zorroGray-2);
  border-radius: var(--mantine-radius-sm);
  color: var(--mantine-color-zorroCoal-9);
  font-size: var(--mantine-font-size-md);
  padding: var(--mantine-spacing-sm);
  padding-right: $custom-item-width;
  font-weight: 400;

  &:hover:not([disabled]) {
    border-color: var(--mantine-color-zorroGray-5);
  }

  &:focus {
    color: var(--mantine-color-zorroCoal-9);
  }

  &[data-error='true'] {
    color: var(--mantine-color-zorroCoal-9);
  }

  &::placeholder {
    color: var(--mantine-color-zorroGray-4);
    font-weight: 400;
  }

  &[disabled] {
    background-color: var(--mantine-color-zorroGray-8);
    border: none;

    &::placeholder {
      color: var(--mantine-color-zorroGray-5);
    }
  }

  &[data-readonly='true'] {
    color: var(--mantine-color-zorroCoal-9);
    border: none;
    padding: 0;
    text-overflow: ellipsis;

    &::placeholder {
      color: var(--mantine-color-zorroCoal-9);
    }
  }

  &[data-readonly='true'] + div button {
    display: none;
  }

  &[data-expanded='true'] {
    border-color: var(--mantine-color-zorroGray-4);
    & + .section[data-position='right'] {
      button {
        pointer-events: all;
        transform: rotate(180deg);
      }
    }
  }
}

.label {
  color: var(--mantine-color-zorroCoal-9);
  font-size: var(--mantine-font-size-sm);
  margin-bottom: 0.25rem;
  font-weight: 400;
}

.section {
  width: $custom-item-width;
}

.option {
  &[data-checked='true'] {
    background-color: var(--mantine-color-zorroPrimaryButtonSelected-0);
  }
  font-size: var(--mantine-font-size-sm);
  border-radius: var(--mantine-radius-sm);
}

.required {
  color: var(--mantine-color-zorroCoal-9);
  position: relative;
  right: 4px;
}

.dropdown {
  border: none;
  box-shadow: 0 6px 20px 0 #0000001a;
}
