.label {
  font-size: var(--mantine-font-size-sm);
  margin-bottom: 0.25rem;
  font-weight: 400;
}

.input {
  padding: var(--mantine-spacing-sm) var(--mantine-spacing-md);
  border-color: var(--mantine-color-zorroGray-2);
  color: var(--mantine-color-zorroCoal-9);
  font-size: var(--mantine-font-size-md);
  font-weight: 500;

  &:hover {
    border-color: var(--mantine-color-zorroGray-4);
  }

  &:focus {
    border-color: var(--mantine-color-zorroCoal-9);
    color: var(--mantine-color-zorroCoal-9);
  }

  &::placeholder {
    color: var(--mantine-color-zorroGray-4);
    font-weight: 400;
  }

  &[disabled] {
    background-color: var(--mantine-color-zorroGray-1);

    &::placeholder {
      color: var(--mantine-color-zorroGray-5);
    }
  }
}
