.root {
  padding: var(--mantine-spacing-sm) var(--mantine-spacing-md);
  gap: var(--mantine-spacing-xs);
  transition: background 0.15s;
  border-radius: 8px;
  min-height: 3.5rem;
  min-width: 3.5rem;
  flex-wrap: nowrap;
  cursor: pointer;

  &[data-disabled='true'] {
    pointer-events: none;
    opacity: 0.5;
  }

  &:hover {
    background: var(--mantine-color-zorroGray-8);
  }

  &[data-checked='true'] {
    background: var(--mantine-color-zorroPrimaryButtonSelected-0);
  }
}
