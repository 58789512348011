.root {
  transition: background-color 0.25s ease-out;
  border-radius: var(--mantine-radius-md);
  background-color: transparent;

  &[data-open='true'],
  &:hover {
    background-color: var(--mantine-color-zorroGray-8);
  }
}
