.root {
  background-color: var(--mantine-color-zorroWhite-0);
  transition: background-color 0.25s ease-out;
  border-radius: var(--mantine-radius-sm);

  &[data-disabled='true'] {
    background-color: var(--mantine-color-zorroGray-8);
    pointer-events: none;
    svg path {
      stroke: var(--mantine-color-zorroGray-2);
    }
  }

  &:hover {
    background-color: var(--mantine-color-zorroGray-8);
  }

  &:active {
    background-color: #e5e7ed;
  }

  &[data-transparent='true'] {
    background-color: transparent;
  }

  &[data-checked='true'] {
    background-color: var(--mantine-color-zorroPrimaryButtonSelected-0);
    &:active,
    &:hover {
      background-color: var(--mantine-color-zorroPlum-0);
    }
  }

  &[data-size='xs'] {
    height: 1rem;
    width: 1rem;
    .inner {
      height: 0.625rem;
      width: 0.625rem;
    }
  }

  &[data-size='sm'] {
    height: 1.5rem;
    width: 1.5rem;
    .inner {
      height: 1rem;
      width: 1rem;
    }
  }

  &[data-size='md'] {
    height: 2rem;
    width: 2rem;
    .inner {
      height: 1.25rem;
      width: 1.25rem;
    }
  }

  &[data-size='lg'] {
    height: 2.5rem;
    width: 2.5rem;
    .inner {
      height: 1.25rem;
      width: 1.25rem;
    }
  }

  &[data-size='xl'] {
    height: 3rem;
    width: 3rem;
    .inner {
      height: 1.25rem;
      width: 1.25rem;
    }
  }

  &[data-oncolor='true'] {
    border: none;
    background-color: transparent;

    &:hover {
      background-color: var(--mantine-color-zorroPlum-9);
    }

    &:active {
      background-color: var(--mantine-color-zorroPrimaryButtonSelected-0);
      svg path {
        stroke: var(--mantine-color-zorroIris-9);
      }
    }

    &[data-disabled='true'] {
      pointer-events: none;
      svg path {
        stroke: var(--mantine-color-zorroGray-2);
      }
    }
  }
}
