$custom-item-width: 54px;

.root {
  &[data-size='lg'] {
    .input {
      min-height: 3rem;
      height: 3rem;
    }
  }

  &[data-size='md'] {
    .input {
      min-height: 2.5rem;
      height: 2.5rem;
    }
  }

  &[data-size='sm'] {
    .input {
      min-height: 2rem;
      height: 2rem;
      font-size: var(--mantine-font-size-sm);
    }
  }
}

.input {
  border-color: var(--mantine-color-zorroGray-2);
  border-radius: var(--mantine-radius-sm);
  color: var(--mantine-color-zorroCoal-9);
  font-size: var(--mantine-font-size-md);
  padding-right: $custom-item-width;
  font-weight: 500;

  &::placeholder {
    color: var(--mantine-color-zorroGray-4);
    font-weight: 400;
  }

  &:hover {
    border-color: var(--mantine-color-zorroGray-5);
  }

  &:focus {
    border-color: var(--mantine-color-zorroGray-4);
    color: var(--mantine-color-zorroCoal-9);
  }

  &[disabled] {
    background-color: var(--mantine-color-zorroGray-8);
    color: var(--mantine-color-zorroGray-4);
    border: none;

    &::placeholder {
      color: var(--mantine-color-zorroGray-4);
    }
  }
}

.label {
  color: var(--mantine-color-zorroCoal-9);
  font-size: var(--mantine-font-size-sm);
  margin-bottom: 0.25rem;
  font-weight: 400;
}

.section {
  width: $custom-item-width;
  pointer-events: none; // Icons on rightSection coming from Mantine have 54px, but the custom ones we provide have a different width, so we hardcode it here to match
}

.option {
  font-size: var(--mantine-font-size-md);
}

.required {
  color: var(--mantine-color-zorroCoal-9);
  position: relative;
  right: 4px;
}
