$main-shadow-offset: 0.75rem;
$main-vertical-padding: 3rem;

.root {
  .main {
    min-height: calc(100dvh - $main-vertical-padding);

    > div {
      background-color: var(--mantine-color-zorroWhite-0);
      margin-top: $main-shadow-offset;
      overflow-x: hidden;
      height: 100%;

      > div {
        background-color: var(--mantine-color-zorroWhite-0);
        padding: $main-vertical-padding 1.25rem;
        height: 100%;
      }
    }
  }

  &[data-desktop='true'] {
    .main {
      > div {
        > div {
          padding: $main-vertical-padding 1.75rem $main-vertical-padding 3.5rem;
          width: calc(
            100vw - var(--app-shell-navbar-offset) - $main-shadow-offset
          );
        }
      }
    }

    &[data-with-sidebar='true'] {
      .main {
        > div {
          border-bottom-left-radius: var(--mantine-radius-lg);
          border-top-left-radius: var(--mantine-radius-lg);
          margin-left: $main-shadow-offset;

          > div {
            box-shadow: -4px -4px 13px -4px rgba(26, 23, 33, 0.08);
            border-top-left-radius: var(--mantine-radius-lg);
          }
        }
      }
    }
  }

  &[data-variant='align-left'] {
    .main {
      > div {
        > div {
          > div {
            max-width: 1200px;
          }
        }
      }
    }
  }
}
