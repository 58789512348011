.controls {
  bottom: -2.5rem;
  padding: 0;
  top: unset;
}

.indicators {
  bottom: -2rem;
}

.viewport {
  border-radius: var(--mantine-radius-md);
}

.control {
  background-color: unset;
  box-shadow: unset;
  border: unset;
  height: 12px;
  width: 6px;

  > svg {
    stroke: var(--mantine-color-zorroCoal-9);
  }
}

.indicator {
  background-color: var(--mantine-color-zorroGray-3);
  height: 8px;
  width: 8px;

  &[data-active] {
    background-color: var(--mantine-color-zorroIris-9);
  }
}
