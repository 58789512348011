.root {
  transition: background-color 0.2s ease-out;
  border-radius: var(--mantine-radius-md);
  padding: var(--mantine-spacing-sm);
  position: relative;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    background-color: var(--mantine-color-zorroPlum-1);
  }

  &[data-checked='true'] {
    background-color: #e0e3fe;

    &:hover {
      background-color: var(--mantine-color-zorroPlum-0);
    }
  }

  &::after {
    border-bottom: 1px solid var(--mantine-color-zorroGray-2);
    position: absolute;
    content: ' ';
    width: 100%;
    bottom: 1px;
    left: 0;
  }
}
