body {
  background-color: var(--mantine-color-zorroGray-10);
}

.display-for-print-only {
  display: none;
}

.intercom-namespace .intercom-app .intercom-messenger-frame {
  transform-origin: right top;
  right: 210px;
  top: 80px;
}

@media print {
  .display-for-print-only {
    display: block;
  }

  .excluded-from-print {
    display: none;
  }

  /* We need to set the header to position: relative, otherwise it appears on every page in the print dialog */
  .mantine-Header-root {
    position: relative;
  }

  /* Changing the navbar position affects the padding provided by AppShell, we need to unset it */
  .mantine-AppShell-main {
    padding: 0 16px;
  }

  .mantine-Navbar-root {
    display: none;
  }
}
