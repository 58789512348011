.root {
  padding: var(--mantine-spacing-md);
  gap: var(--mantine-spacing-xs);
  transition: background 0.15s;
  border-radius: 8px;
  min-width: 3.5rem;
  flex-wrap: nowrap;
  cursor: pointer;

  &:hover {
    background: var(--mantine-color-zorroGray-8);
  }

  &[data-checked='true'] {
    background: var(--mantine-color-zorroPrimaryButtonSelected-0);
  }
}
