.mark {
  opacity: 0;
}

.bar {
  background: var(--mantine-color-zorroIris-4);
}

.thumb {
  border-color: var(--mantine-color-zorroIris-4);
  border-width: 3px;
  height: 20px;
  width: 20px;
}

.markLabel {
  color: var(--mantine-color-zorroCoal-9);
}

.label {
  border: 1px solid var(--mantine-color-zorroIris-9);
  background: var(--mantine-color-zorroWhite-0);
  color: var(--mantine-color-zorroIris-9);
}
