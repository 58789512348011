.root {
  cursor: pointer;
  flex-wrap: nowrap;
  width: 100%;
  overflow: hidden;
  gap: 0;
  &:hover {
    text-decoration: underline;
    text-underline-offset: 3px;
  }
}
