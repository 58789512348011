.root {
  .label {
    font-size: var(--mantine-font-size-sm);
    margin-bottom: 0.25rem;
    font-weight: 400;
  }

  .input {
    border-color: var(--mantine-color-zorroGray-6);
    padding-right: 54px;
  }

  &[data-variant='rectangle'] {
    .input {
      padding: 5px 16px;
      overflow-y: clip;
      overflow-x: auto;
      height: 44px;
    }
  }

  .inputField {
    font-size: var(--mantine-font-size-md);
    color: var(--mantine-color-zorroCoal-9);
    padding: var(--mantine-spacing-md) 0;
    font-weight: 500;

    &::placeholder {
      color: var(--mantine-color-zorroGray-4);
      font-weight: 400;
    }
  }

  .inputField {
    &:not(:only-child)::placeholder {
      color: transparent;
    }
  }

  .option {
    font-size: var(--mantine-font-size-sm);
  }

  .pill {
    background-color: var(--mantine-color-zorroIris-1);
    font-size: var(--mantine-font-size-md);

    > span {
      align-items: center;
      display: flex;
      margin-right: 4px;
    }
  }

  &[data-variant='rectangle'] {
    .pill {
      background-color: var(--mantine-color-zorroPrimaryButtonSelected-0);
      border-radius: 4px;
    }
  }

  .wrapper {
    &[data-disabled='true'] {
      background-color: var(--mantine-color-zorroGray-1);
    }
  }

  .content {
    overflow-x: hidden;
  }

  .close {
    color: var(--mantine-color-zorroCoal-9);
    height: 32px;
    width: 32px;

    > svg {
      height: 32px;
      width: 32px;
    }
  }

  .required {
    color: var(--mantine-color-zorroCoal-9);
    position: relative;
    right: 4px;
  }
}
