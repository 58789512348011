.root {
  &[data-size='lg'] {
    .input {
      min-height: 3rem;
      height: 3rem;
    }
  }

  &[data-size='md'] {
    .input {
      min-height: 2.5rem;
      height: 2.5rem;
    }
  }

  &[data-size='sm'] {
    .input {
      min-height: 2rem;
      height: 2rem;
      font-size: var(--mantine-font-size-sm);
    }
  }

  &[data-readonly="true"] {
    .input {
      border:  none;
      padding-left: 0;
    }
  }
}

.label {
  font-size: var(--mantine-font-size-sm);
  margin-bottom: 0.25rem;
  font-weight: 400;
}

.root {
  &:has(input[data-expanded='true']) {
    .input {
      border-color: var(--mantine-color-zorroGray-4);
    }
  }
}

.input {
  border-radius: var(--mantine-radius-sm);
  border-color: var(--mantine-color-zorroGray-2);
  color: var(--mantine-color-zorroCoal-9);
  font-size: var(--mantine-font-size-md);
  font-weight: 500;
  border-radius: var(--mantine-radius-sm);
  padding-right: 3.375rem;

  &:hover {
    border-color: var(--mantine-color-zorroGray-5);
  }

  &[data-error='true'] {
    color: var(--mantine-color-zorroCoal-9);
  }

  & + .section[data-position='right'] {
    width: fit-content;
    padding: 0 var(--mantine-spacing-md);
    pointer-events: none;
    .target {
      pointer-events: all;
      color: var(--mantine-color-zorroCoal-9);
      background-color: #f7f9fb;
      padding: 0.125rem 0.5rem;
      height: fit-content;
      &:hover:not([disabled]) {
        background-color: #f7f9fb;
      }
    }
  }

  &[data-disabled='true'] {
    background-color: var(--mantine-color-zorroGray-8);
    color: var(--mantine-color-zorroGray-4);
    border: none;

    &::placeholder {
      color: var(--mantine-color-zorroGray-4);
    }
  }
}

.inputField {
  &::placeholder {
    color: var(--mantine-color-zorroGray-4);
    font-weight: 400;
  }
}

.pill {
  background-color: var(--mantine-color-zorroPrimaryButtonSelected-0);
  font-size: var(--mantine-font-size-md);
  border-radius: 0.25rem;
  font-weight: 400;
  max-width: calc(100% - 6.75rem);

  > span {
    align-items: center;
    margin-right: 0.25rem;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.pillsList {
  flex-wrap: nowrap;
}

.required {
  color: var(--mantine-color-zorroCoal-9);
  position: relative;
  right: 0.25rem;
}

.popoverDropdown {
  border: none;
  box-shadow: 0px 6px 20px 0px #0000001a;
  width: fit-content;
  max-width: 15rem;
  padding: var(--mantine-spacing-xs);
  border-radius: var(--mantine-radius-sm);
  display: flex;
  flex-direction: row;
}
.popoverWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
}
.dropdownPill {
  background-color: #e0e3fe;
  font-size: var(--mantine-font-size-sm);
  padding: 0.125rem 0.5rem;
  width: fit-content;
  max-width: 100%;
  border-radius: var(--mantine-radius-sm);
  display: flex;
  flex-wrap: nowrap;
  span {
    font-size: var(--mantine-font-size-sm);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
