.root {
  &:has(.input[aria-expanded='true']) {
    .input {
      border-color: var(--mantine-color-zorroGray-4);
    }
  }

  &[data-size='lg'] {
    .input {
      min-height: 3rem;
      height: 3rem;
    }
  }

  &[data-size='md'] {
    .input {
      min-height: 2.5rem;
      height: 2.5rem;
    }
  }

  &[data-size='sm'] {
    .input {
      min-height: 2rem;
      height: 2rem;
      font-size: var(--mantine-font-size-sm);
    }
  }
}

.input {
  border-color: var(--mantine-color-zorroGray-2);
  border-radius: var(--mantine-radius-sm);
  color: var(--mantine-color-zorroCoal-9);
  font-size: var(--mantine-font-size-md);
  font-weight: 500;

  &::placeholder {
    color: var(--mantine-color-zorroGray-4);
    font-weight: 400;
  }

  &:hover {
    border-color: var(--mantine-color-zorroGray-5);
  }

  &[disabled] {
    background-color: var(--mantine-color-zorroGray-1);

    &::placeholder {
      color: var(--mantine-color-zorroGray-5);
    }
  }
}

.section {
  padding-right: var(--mantine-spacing-md);
  // Workaround to make clicks on the calendar icon trigger opening the calendar
  pointer-events: none;
}

.label {
  font-size: var(--mantine-font-size-sm);
  margin-bottom: 0.25rem;
  font-weight: 400;
}

.day {
  &:not(:disabled):not([data-outside]) {
    color: var(--mantine-color-gray-7);
    &:hover {
      background-color: var(--mantine-color-zorroGray-10);
    }

    &[data-selected] {
      background-color: var(--mantine-color-zorroPrimaryButtonSelected-0);
      &:hover {
        background-color: var(--mantine-color-zorroPlum-0);
      }
    }
  }
}

.monthsListControl,
.yearsListControl,
.day {
  border-radius: var(--mantine-radius-sm);
  color: var(--mantine-color-zorroCoal-9);
  &:not(:disabled):not([data-outside]) {
    &:hover {
      background-color: var(--mantine-color-zorroGray-10);
    }

    &[data-in-range='true'] {
      background-color: var(--mantine-color-zorroGray-10);
      &:hover {
        background-color: var(--mantine-color-zorroPlum-0);
      }
    }

    &[data-selected] {
      background-color: var(--mantine-color-zorroPrimaryButtonSelected-0);
      &:hover {
        background-color: var(--mantine-color-zorroPlum-0);
      }
    }
  }
}

.required {
  color: var(--mantine-color-zorroCoal-9);
  position: relative;
  right: 4px;
}
