.root {
  border: 1px solid var(--mantine-color-zorroGray-2);
  border-radius: var(--mantine-radius-sm);
  padding: 4px;

  &[data-size='lg'] {
    height: 3rem;
  }

  &[data-size='md'] {
    min-height: 2.5rem;
    height: 2.5rem;
  }

  &[data-size='sm'] {
    min-height: 2rem;
    height: 2rem;
    font-size: var(--mantine-font-size-sm);
  }
}

.tab {
  background-color: var(--mantine-color-zorroWhite-0);
  border-radius: var(--mantine-radius-sm);
  padding: var(--mantine-spacing-xs);
  cursor: pointer;
  border: none;
  flex: 1;

  &[data-active] {
    background-color: var(--mantine-color-zorroPrimaryButtonSelected-0);
  }

  &[data-disabled] {
    opacity: 1;
  }

  &:hover {
    background-color: var(--mantine-color-zorroGray-10);
  }

  &:hover[data-active] {
    background-color: var(--mantine-color-zorroPlum-0);
  }
}

.tabLabel {
  color: var(--mantine-color-zorroCoal-9);
  font-size: var(--mantine-font-size-md);
}

.list {
  display: flex;
  height: 100%;

  &::before {
    content: none;
  }
}
