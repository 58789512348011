.content,
.subContent {
  min-width: 100px;
  background-color: white;
  border-radius: var(--mantine-radius-md);
  padding: var(--mantine-spacing-xs);
  box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.1);
  animation-duration: 400ms;
  z-index: 500;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
  &[data-side='top'] {
    animation-name: slideDownAndFade;
  }
  &[data-side='right'] {
    animation-name: slideLeftAndFade;
  }
  &[data-side='bottom'] {
    animation-name: slideUpAndFade;
  }
  &[data-side='left'] {
    animation-name: slideRightAndFade;
  }
}

.item,
.subTrigger {
  color: var(--mantine-color-zorroCoal-6);
  font-size: var(--mantine-font-size-sm);
  padding-left: var(--mantine-spacing-xs);
  padding-right: var(--mantine-spacing-xs);
  align-items: center;
  position: relative;
  font-weight: 400;
  height: 2rem;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  display: flex;
  outline: none;
  &[data-disabled] {
    color: var(--mantine-color-zorroGray-6);
    pointer-events: none;
  }
  &[data-highlighted] {
    background-color: #e0e3fe;
  }
}

.subTrigger[data-state='open']:not([data-highlighted], [data-disabled]) {
  background-color: var(--mantine-color-zorroGray-8);
}

.label {
  color: var(--mantine-color-zorroGray-4);
  font-size: var(--mantine-font-size-xs);
  padding-left: var(--mantine-spacing-sm);
}

.separator {
  background-color: var(--mantine-color-zorroGray-2);

  margin: 0.5rem 0;
  height: 1px;
}

.arrow {
  fill: white;
}

@keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideRightAndFade {
  from {
    opacity: 0;
    transform: translateX(-2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideLeftAndFade {
  from {
    opacity: 0;
    transform: translateX(2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
