.root {
  .required {
    color: var(--mantine-color-zorroCoal-0);
    position: relative;
    right: 4px;
  }

  .label {
    font-size: var(--mantine-font-size-sm);
    margin-bottom: 0.25rem;
    font-weight: 400;
    align-self: center;
  }

  .description {
    color: var(--mantine-color-zorroCoal-0);
    padding-bottom: var(--mantine-spacing-xs);
  }

  &[data-variant='with-description'] {
    .label {
      padding-bottom: var(--mantine-spacing-md);
      font-weight: 600;
    }
  }

  > div[role='radiogroup'] {
    width: 100%;
  }

  &[data-variant='vertical'] {
    align-items: start;
    flex-direction: column;

    .label {
      align-self: start;
      margin-bottom: 0.75rem;
    }
  }

  &[data-readonly='true'] {
    [class*='indicator'] {
      display: none;
    }
  }

  &:has(button[disabled]) {
    button:not([data-checked='true']) {
      display: none;
    }
  }
}
