.table {
  border: 1px solid var(--mantine-color-zorroGray-3);
  border-color: var(--mantine-color-zorroGray-3);
  background: var(--mantine-color-zorroWhite-0);
  border-radius: var(--mantine-radius-md);
  border-collapse: separate;
  border-bottom: none;
  border-spacing: 0;
  contain: paint;
}

.th {
  border: 1px solid var(--mantine-color-zorroGray-3);
  color: var(--mantine-color-zorroCoal-9);
  font-weight: 600;
  border-left: none;
  border-top: none;
  &:last-child {
    border-right: none;
  }
}

.td {
  border: 1px solid var(--mantine-color-zorroGray-3);
  border-left: none;
  border-top: none;
  &:last-child {
    border-right: none;
  }
}
