.root {
  .body {
    box-shadow: inset 0 0 0 1px var(--mantine-color-zorroGray-6);
    background-color: var(--mantine-color-zorroWhite-0);
    border-radius: var(--mantine-radius-md);
    justify-content: center;
    display: flex;
    height: 5rem;
  }

  .inner {
    display: inline-flex;
    margin: auto 1rem;

    svg {
      min-width: 16px;
      height: 100%;
      width: 100%;
    }
  }

  .radio {
    display: none;
  }

  .labelWrapper {
    color: var(--mantine-color-zorroCoal-9);
    justify-content: center;
    display: inline-flex;
    position: absolute;
    overflow: hidden;
    inset: 0;
  }

  &[data-readonly='true'] {
    .icon {
      display: none;
    }
  }

  .label {
    line-height: 7.5rem;
    white-space: nowrap;
    font-weight: 400;
    padding-left: 0;
    margin-left: 0;
    opacity: 0;
    z-index: 1;
  }

  &[data-checked='true'] {
    .body {
      box-shadow: inset 0 0 0 2px var(--mantine-color-zorroIris-9);
    }

    .label {
      font-weight: 600;
    }
  }

  &[data-desktop='true'] {
    .body {
      justify-content: flex-start;
      height: 7.5rem;
    }

    .inner {
      height: auto 0 auto 1.5rem;
    }

    .label {
      opacity: 1;
    }
  }
}
