.root {
  &[data-size='lg'] {
    .input {
      min-height: 3rem;
      height: 3rem;
    }
  }

  &[data-size='md'] {
    .input {
      min-height: 2.5rem;
      height: 2.5rem;
    }
  }

  &[data-size='sm'] {
    .input {
      min-height: 2rem;
      height: 2rem;
      font-size: var(--mantine-font-size-sm);
    }
  }

  & [readonly] {
    padding: 0;
    border: none;
  }
}

.label {
  font-size: var(--mantine-font-size-sm);
  margin-bottom: 0.25rem;
  font-weight: 400;
}

.input {
  border-color: var(--mantine-color-zorroGray-2);
  color: var(--mantine-color-zorroCoal-9);
  font-size: var(--mantine-font-size-md);
  font-weight: 500;
  border-radius: var(--mantine-radius-sm);

  &:hover {
    border-color: var(--mantine-color-zorroGray-5);
  }

  &:focus {
    border-color: var(--mantine-color-zorroGray-4);
    color: var(--mantine-color-zorroCoal-9);
  }

  &::placeholder {
    color: var(--mantine-color-zorroGray-4);
    font-weight: 400;
  }

  &[disabled] {
    background-color: var(--mantine-color-zorroGray-8);
    color: var(--mantine-color-zorroGray-4);
    border: none;

    &::placeholder {
      color: var(--mantine-color-zorroGray-4);
    }

    &:hover {
      border-color: var(--mantine-color-zorroGray-8);
    }
  }
}

.required {
  color: var(--mantine-color-zorroCoal-9);
  position: relative;
  right: 4px;
}
