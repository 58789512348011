.root {
  width: 100%;

  .label {
    font-weight: 600;
    padding: 0;
  }

  .panel {
    padding: 0 var(--mantine-spacing-xl);
  }

  .content {
    padding: 0 0 var(--mantine-spacing-md) 0;
  }

  .control {
    padding: var(--mantine-spacing-sm) var(--mantine-spacing-xl);

    &:hover {
      background-color: unset;
    }
  }

  .item {
    border: 1px solid var(--mantine-color-zorroGray-2);
  }

  .icon {
    margin-inline-end: var(--mantine-spacing-xs);
  }

  .chevron {
    justify-content: center;
  }

  &[data-variant='separated'] {
    .control[data-active='true'] {
      border-bottom: 1px solid var(--mantine-color-zorroGray-2);
    }

    .content {
      padding: var(--mantine-spacing-md) 0;
    }

    .item {
      background: white;
    }
  }
}
