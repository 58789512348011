.root {
  transition: background-color ease-out 0.25s;
  border-radius: var(--mantine-radius-md);
  cursor: pointer;
  padding: 0;
  position: relative;
  background-color: var(--mantine-color-zorroGray-10) !important;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23676979FF' stroke-width='1' stroke-dasharray='6' stroke-dashoffset='7' stroke-linecap='square'/%3e%3c/svg%3e") !important;
  border-radius: 8px;
  flex-basis: 100%;
  &:hover {
    transition: background-color ease-out 0.25s;
    background-color: var(--mantine-color-zorroGray-8) !important;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23676979FF' stroke-width='1' stroke-dasharray='6' stroke-dashoffset='7' stroke-linecap='square'/%3e%3c/svg%3e") !important;
  }

  &[data-disabled='true'] {
    cursor: not-allowed;
  }
}
