.label {
  font-size: var(--mantine-font-size-sm);
  margin-bottom: 0.25rem;
  font-weight: 400;
}

.root {
  &:has(input:focus) {
    .input {
      border-color: var(--mantine-color-zorroGray-4);
      color: var(--mantine-color-zorroCoal-9);
    }
  }
}

.input {
  border-color: var(--mantine-color-zorroGray-2);
  border-radius: var(--mantine-radius-sm);
  color: var(--mantine-color-zorroCoal-9);

  &[data-error='true'] {
    color: var(--mantine-color-zorroCoal-9);
  }

  &:hover {
    border-color: var(--mantine-color-zorroGray-5);
  }

  &[data-disabled='true'] {
    background-color: var(--mantine-color-zorroGray-8);
    color: var(--mantine-color-zorroGray-4);
    border: none;

    &::placeholder {
      color: var(--mantine-color-zorroGray-4);
    }
  }
}

.innerInput {
  font-size: var(--mantine-font-size-md);
  font-weight: 500;

  &::placeholder {
    color: var(--mantine-color-zorroGray-4);
    font-weight: 400;
  }
}

.required {
  color: var(--mantine-color-zorroCoal-9);
  position: relative;
  right: 4px;
}
