.dropdown {
  box-shadow: 0 0.3rem 1.25rem 0 #0000001a;
}

.selected-option:not([data-readonly='true']),
.option {
  user-select: none;
  cursor: pointer;
}

.selected-option {
  border-radius: 0.5rem;
  border: 1px solid var(--mantine-color-zorroGray-3);

  &:hover,
  &[data-opened='true'],
  &[data-readonly='true'] {
    background-color: var(--mantine-color-zorroGray-10);
  }
  &:hover {
    border-color: var(--mantine-color-zorroGray-6);
  }
  &[data-opened='true'] {
    border-color: var(--mantine-color-zorroGray-10);
  }
  &[data-readonly='true'] {
    border-color: var(--mantine-color-zorroGray-10);
  }
}

.option {
  border-radius: 0.25rem;

  &:hover {
    background-color: var(--mantine-color-zorroPrimaryButtonSelected-0);
  }

  &[data-selected='true'] {
    background-color: var(--mantine-color-zorroGray-10);
  }
}
