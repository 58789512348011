.header {
  z-index: 201;
  height: 80px;
}

.content {
  overflow-x: hidden;
  box-shadow: none;
}

.close {
  color: var(--mantine-color-zorroGray-6);
  height: 32px;
  width: 32px;

  > svg {
    height: 32px;
    width: 32px;
  }
}
