.root {
  border-radius: var(--mantine-radius-sm);
  width: 100%;
  height: 3rem;
  padding: 0 var(--mantine-spacing-sm) 0 var(--mantine-spacing-xs);

  &:hover {
    border-color: var(--mantine-color-zorroGray-5);
  }

  &[data-readonly='true'] {
    pointer-events: none;
  }

  &[disabled] {
    border: none;
    pointer-events: none;
  }

  .iconWrapper {
    width: 2rem;
    height: 2rem;
    overflow: hidden;
    border-radius: var(--mantine-radius-sm);
    flex-shrink: 0;
    margin-right: var(--mantine-spacing-sm);
    svg {
      width: 20px;
      height: 20px;
    }
  }

  .input {
    display: none;
  }

  &[data-has-description='true'] {
    height: fit-content;
    padding: 1rem;

    .iconWrapper {
      height: 4rem;
      width: 4rem;

      svg {
        width: 40px;
        height: 40px;
      }
    }
  }
}
